@import '../../../sass/_variables';

.card-pile {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $card-width;
  height: $card-height;
  border-radius: $card-border-radius;
  position: relative;
  color: $color-gray-dark;

  &:hover {
    box-shadow: $box-shadow-deck-active-hover;
  }

  &-count {
    position: absolute;
    font-weight: bold;
    top: -3rem;
  }

  .card-image {
    box-shadow: $box-shadow-deck-card;
  }
}
