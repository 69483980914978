@import '../../../sass/_main';

.card {
  width: $card-width;
  height: $card-height;
  position: relative;

  &-value {
    &-container {
      background-color: #fff;
      display: flex;
      position: absolute;
      font-weight: bold;
      font-size: 1.4rem;
      left: 0.6rem;
      bottom: 0;
      border-radius: 50%;
    }
  }

  &-suit {
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 0;
    box-shadow: none;
  }

  &-image {
    width: 100%;
    height: 100%;
    cursor: pointer;
    box-shadow: $box-shadow-primary;
    border-radius: $card-border-radius;

    &:hover,
    &:active {
      box-shadow: $box-shadow-card-active;
    }
  }

  &-disabled {
    pointer-events: none;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      border-radius: $card-border-radius;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  &-enlarged {
    width: $card-width * 2.5;
    height: $card-height * 2.5;

    .card-value-container {
      align-items: center;
      font-size: 2.6rem;
      left: 1.2rem;
      bottom: 0.2rem;
      padding: 0 0.5rem;
      border-radius: 20%;
      margin-right: 0.2rem;
    }

    .card-suit {
      height: 2.6rem;
      width: 2.6rem;
    }
  }
}

.card-dragging {
  pointer-events: none;
  cursor: grab;
  z-index: 100;

  &:active {
    box-shadow: $box-shadow-card-active;
  }
}
