.spinner {
  display: inline-block;
  width: 5rem;
  height: 5rem;
  border: 3px solid #d4d4d4;
  border-radius: 50%;
  border-top-color: #333;
  animation: spin 0.5s ease-in-out infinite;
  -webkit-animation: spin 0.5s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
