@import '../../sass/_variables';

.modal {
  display: flex;
  flex-direction: column;
  position: fixed;
  box-shadow: 0 0.1rem 0.7rem $color-gray-medium;
  border-radius: $card-border-radius;
  padding: 2rem;
  align-self: center;
  background: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2rem;
    padding-left: 1rem;
    margin-bottom: 2rem;

    &-text {
      font-weight: bold;
      padding: 0.75rem 0 0.5rem;
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 3;
    background-color: rgba(black, 0.4);
    cursor: auto;
  }

  &__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    color: $color-gray-dark;
    padding: 0 3rem;
  }

  &__close {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    font-weight: 300;
    font-size: 2.5rem;
    cursor: pointer;
    line-height: 0.8;

    &:hover {
      color: red;
    }
  }
}
