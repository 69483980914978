@import '../../../sass/_main';

.common-cards {
  display: flex;
  grid-column: 4 / span 2;
  grid-row: 4 / span 2;
  justify-self: center;
  align-self: center;
  justify-content: space-around;
  align-items: center;
  padding: 8rem 5rem;
  border-radius: 50%;
  border: 4px solid $color-primary;
  box-shadow: $box-shadow-light;

  & > * {
    margin: 0 2rem;
  }
}

.game-table {
  position: relative;
  display: grid;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  grid-template-columns: repeat(8, 12.5%);
  grid-template-rows: repeat(8, 12.5%);
  background: #fafafa;
  @include generalStore;
}

.game-table--2-players {
  @include player0;
  @include player1(2);
}

.game-table--3-players {
  @include player0;
  @include player1(3);
  @include player2(3);
}

.game-table--4-players {
  @include player0;
  @include player1(4);
  @include player2(4);
  @include player3(4);
}

.game-table--5-players {
  @include player0;
  @include player1(5);
  @include player2(5);
  @include player3(5);
  @include player4(5);
}

.game-table--6-players {
  @include player0;
  @include player1(6);
  @include player2(6);
  @include player3(6);
  @include player4(6);
  @include player5(6);
}

.game-table--7-players {
  @include player0;
  @include player1(7);
  @include player2(7);
  @include player3(7);
  @include player4(7);
  @include player5(7);
  @include player6(7);
}

.game-table--8-players {
  @include player0;
  @include player1(8);
  @include player2(8);
  @include player3(8);
  @include player4(8);
  @include player5(8);
  @include player6(8);
  @include player7(8);
}

.modal-buttons {
  display: flex;
  align-self: center;
  margin-top: 3rem;
  margin-bottom: 1rem;

  button {
    font-size: 1.8rem;
    padding: 1rem 2rem;
  }
}
