@import '../../../sass/_variables';

.tomb {
  height: $card-height;
  width: $card-width;
}

.player-role-img {
  width: $card-width;
  height: $card-height;
  transform: rotate(15deg);
  border-radius: $card-border-radius;
  box-shadow: $box-shadow-primary;
}

.player-dead-name {
  text-align: left;
  font-weight: bold;
}
