@import './sass/_mixins';

html {
  font-size: 59.375%;

  @include respond(phone) {
    font-size: 23.4375%;
  }

  @include respond(tab-port) {
    font-size: 35.15625%;
  }

  @include respond(tab-land) {
    font-size: 46.875%;
  }

  @include respond(big-desktop) {
    font-size: 62.5%;
  }
}

body {
  margin: 0;
  font-family: 'Chivo', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  box-sizing: border-box;
  color: #3f3f3f;
  font-size: 1.6rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* .bgio-client {
  background-color: rgb(255, 255, 255);
} */
