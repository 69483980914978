@import '../../../sass/_variables';

.game-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
  text-align: center;

  span {
    font-size: 3.2rem;
  }

  &-winners {
    display: flex;
    justify-content: center;
    align-items: center;

    & > * {
      &:not(:last-child),
      &:not(:only-child) {
        margin-right: 4rem;
      }
    }
  }

  &-winner-character-image {
    margin-bottom: 4rem;
    box-shadow: $box-shadow-secondary;
    border-radius: $card-border-radius;
  }

  &-buttons {
    display: flex;
    margin-top: 15rem;
  }
}
