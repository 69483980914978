@import '../../../sass/_variables';

.discarded {
  width: $card-width;
  height: $card-height;
  border: dashed 4px $color-gray-light;
  cursor: pointer;

  &--active {
    box-shadow: $box-shadow-deck-active;
  }
}
