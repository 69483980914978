@import '../../../sass/_variables';

.draggable-card,
.draggable-card-selected {
  position: relative;
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  z-index: 10;
  margin-right: -4%;
  border-radius: $card-border-radius;
}

.draggable-card-selected {
  box-shadow: $box-shadow-card-selected;
  transform: translateY(-2rem);
}
