.game-header {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 8rem;

  span {
    margin: 0 2rem;
    text-decoration: underline;
    cursor: pointer;
  }
}
