.custom-input {
  border: none;
  font-family: inherit;
  font-size: 1.8rem;
  font-weight: 600;
  padding: 2rem 4rem;
  background-color: #e7e7e7;
  min-width: 45rem;
  outline: none;
  margin-right: 1.5rem;
  border-radius: 5px;
  box-shadow: 2px 2px 5px #ccc;
  text-align: center;
  cursor: text;
}
