@import '../../../sass/_variables';

.player-hp-container {
  position: relative;
  display: flex;
  text-align: center;
  border-radius: 5px;
  z-index: 2;

  .hp-bar {
    -webkit-appearance: none;
    appearance: none;
    align-self: center;
    height: 1.5rem;
    border: none;
    box-shadow: 0 0.3rem 1rem rgba(0, 0, 0, 0.4);

    &::-webkit-progress-bar {
      background-color: white;
    }

    &::-webkit-progress-value {
      background-color: #1b8b00;
      background-image: linear-gradient(50deg, #a2d240 0%, #1b8b00 74%);
      transition: width 0.75s;
    }

    &-danger {
      &::-webkit-progress-value {
        background: red;
      }
    }
  }
}

.overlay {
  &-steps {
    position: absolute;
    display: flex;
    top: 0;
    width: 100%;
    height: 1.5rem;
  }

  &-step {
    flex: 1;
    &:not(:last-child) {
      border-right: 2px solid #fff;
    }
  }
}
