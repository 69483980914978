@import '../../../sass/_variables';

.player-button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  height: 4rem;
  width: 4rem;
  padding: 0.5rem;
  border: none;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: $box-shadow-secondary;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &:hover {
    transform: scale(1.05);
    box-shadow: $box-shadow-primary;
  }

  &:active {
    transform: scale(0.95);
  }

  &-disabled {
    background-color: gray;
  }
}

.damage-icon {
  fill: red;
}
