.create-game {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 1.8rem;

  h3 {
    margin-bottom: 3rem;
  }

  &-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
  }
}
