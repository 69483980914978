@import '../../sass/_main';

.lobby {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 1.8rem;

  &-link-container {
    display: flex;
    justify-content: center;
    margin: 3rem 0;
  }

  &-player {
    position: relative;
    border-bottom: 4px solid rgb(136, 136, 136);

    &::after {
      content: '';
      position: absolute;
      height: 0.5rem;
      width: 1.5rem;
      left: 0;
      background-color: #fff;
      opacity: 0.3;
      animation: dashToRight 0.8s linear infinite;
    }

    &-active {
      border-bottom: 5px solid green;

      &::after {
        display: none;
      }
    }
  }
}
