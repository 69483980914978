.player-hand {
  display: flex;
  position: relative;

  & > .draggable-card {
    &:hover {
      & ~ .draggable-card {
        transform: translateX(25%);
      }
    }
  }
}
