@import '../../../sass/_variables';

.player {
  align-self: center;
  justify-self: center;
  position: relative;
  display: flex;
  flex-direction: column;

  .player-equipments-container {
    position: absolute;
    display: flex;
    flex-direction: column;

    & > * {
      &:last-child:not(:only-child) {
        margin-top: -$card-height / 1.5;
      }
    }
  }
}
