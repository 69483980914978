@import '../../sass/_main';

.more-options {
  position: absolute;
  display: flex;
  flex-direction: column;
  font-weight: normal;
  bottom: 100%;
  left: 0;
  color: $color-gray-dark;
  text-align: center;
  background-color: #fff;
  padding: 0.5rem 0;
  box-shadow: $box-shadow-dark;
  border-radius: 5px;
  z-index: 100;
  overflow-y: auto;
}

.more-options-item {
  padding: 0.5rem 2rem;
  cursor: pointer;

  &:hover {
    background-color: $color-gray-light;
  }
}
