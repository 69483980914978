@import '../../sass/_main';

.custom-button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
  border: none;
  text-align: center;
  font-family: inherit;
  font-size: 1.8rem;
  font-weight: 600;
  color: #fff;
  background: $color-tertiary;
  border-radius: 5px;
  padding: 1.5rem 4rem;
  opacity: 0.9;
  box-shadow: $box-shadow-primary;
  cursor: pointer;

  &:not(:only-child):not(:last-child) {
    margin-right: 1.5rem;
  }

  &:active {
    transform: translate(0.1rem, 0.1rem);
    box-shadow: none;
  }

  &:hover {
    opacity: 1;
  }
}
